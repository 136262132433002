//* #region ----------------------------------------------- Types

type T = MU_AuthFormLink & {
	globalEvents: {
		"auth-form-link-clicked": {
			target: Attributes["d_target"]
		}
	}
}

type C = T["AuthFormLink"]
type E = T["globalEvents"]
type Attributes = C["attr"]

export type T_AuthFormLink = T

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only utility/helper modules)

import { TypedComponent, defineComponent, makeAppEvents } from "haq"

//* #endregion ----------------------------------------------- Module Imports (only utility/helper modules)

export class AuthFormLink extends HTMLElement {
	private Component: ReturnType<typeof TypedComponent<C>>

	private INITIALIZED = false

	constructor() {
		super()

		this.Component = TypedComponent<C>(this)
	}

	//* ---------- Initialize -----------------------------------------------

	connectedCallback() {
		if (this.INITIALIZED) return

		this.Component.addEventListener("click", () => {
			const target = this.Component.getAttribute("d_target")
			if (!target) return

			const linkEvent = makeAppEvents<E>()
			linkEvent.emit({
				action: "auth-form-link-clicked",
				data: { target }
			})
		})

		this.INITIALIZED = true
	}

	//* ---------- Exposed Methods -----------------------------------------------

	setTarget(value: Attributes["d_target"]) {
		this.Component.setAttribute("d_target", value)
	}
}

export default function AuthFormLinkInit() {
	defineComponent<C>("auth-form-link", AuthFormLink)
}
