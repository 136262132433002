//* #region ----------------------------------------------- Types

import type { AppEventData } from "haq"
import type { GlobalActions } from "./event-router.js"

type T = MU_RootDocument["RootDocument"]

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only utility/helper modules)

import makeLoginForm from "@ComposedComponents/AuthForms/LoginForm.js"
import makeResetPassModal from "@ComposedComponents/AuthForms/ResetPassModal.js"
import AppBtnInit from "@WebComponents/AppBtn/AppBtn.js"
import AuthFormLinkInit from "@WebComponents/Auth/AuthFormLink/AuthFormLink.js"
import MyModalInit from "@WebComponents/MyModal/MyModal.js"
import PasswordInputInit from "@WebComponents/PasswordInput/PasswordInput.js"
import ToastNotifInit from "@WebComponents/ToastNotif/ToastNotif.js"
import { getRootDoc } from "front-end-helpers"
import EVENT_ROUTER from "./event-router.js"

//* #endregion ----------------------------------------------- Module Imports (only utility/helper modules)

function makeApp() {
	let LoginForm: ReturnType<typeof makeLoginForm>
	let ResetPassModal: ReturnType<typeof makeResetPassModal>

	let initialized = false

	_defineWebComponents()

	return Object.freeze({
		init,
		resize,
		executeEvent,
		getAppComponents
	})

	function _defineWebComponents() {
		AppBtnInit()
		PasswordInputInit()
		MyModalInit()
		AuthFormLinkInit()
		ToastNotifInit()
	}

	function init() {
		if (initialized) return
		initialized = true

		LoginForm = makeLoginForm()
		ResetPassModal = makeResetPassModal()
	}

	function resize() {
		const vh = window.innerHeight / 100
		const RootDoc = getRootDoc<T>()
		RootDoc.style.setProperty("--_vh", `${vh}px`)
	}

	function executeEvent<T extends GlobalActions, K extends keyof GlobalActions>(detail: AppEventData<T, K>) {
		const event = EVENT_ROUTER.get(detail.action)
		event.execute(detail.data)
	}

	function getAppComponents() {
		return { LoginForm, ResetPassModal }
	}
}

const App = makeApp()
export default App
