//* #region ----------------------------------------------- Types

import type { MyComponentTypes } from "haq"

type T = MU_ResetPassModal & {
	globalEvents: {
		"reset-form-submitted": {
			Form: Y["ResetPassForm"]
			SubmitBtn: Y["SubmitBtn"]
			email: string
		}
	}
}

type Y = MyComponentTypes<T["ResetPassForm"]>
type E = T["globalEvents"]

export type T_ResetPassModal = T

//* #endregion ----------------------------------------------- Types

//* #region -----------------------------------------------  Module Imports (only utility/helper modules)

import { $, TypedFormData, makeAppEvents } from "haq"

//* #endregion -----------------------------------------------  Module Imports (only utility/helper modules)

export default function makeResetPassModal() {
	let initialized = false

	const Modal = $<T["ResetPassModal"]>(document, "[x_selector='ResetPassModal']")[0]
	const Form = $<T["ResetPassForm"]["ResetPassForm"]>(Modal, "form")[0]
	const SubmitBtn = $<T["ResetPassForm"]["SubmitBtn"]>(Form, "[x_selector='submitBtn']")[0]

	const AppEvents = makeAppEvents<E>()

	_init()

	return Object.freeze({
		show,
		hide
	})

	//* ---------- Initialize -----------------------------------------------

	function _init() {
		if (initialized) return

		Form.addEventListener("submit", _onFormSubmit)

		initialized = true
	}

	function show() {
		Modal.show()
	}

	function hide() {
		Modal.hide()
	}

	function _onFormSubmit(e: SubmitEvent) {
		e.preventDefault()
		const FormData = TypedFormData<T["ResetPassForm"]["FormData"]>(Form)

		AppEvents.emit({
			action: "reset-form-submitted",
			data: {
				Form,
				SubmitBtn,
				email: FormData.get("email")?.trim().toLowerCase() || ""
			}
		})
	}
}
