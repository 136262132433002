//* #region ----------------------------------------------- Types

import type { GlobalActions } from "../init/event-router.js"

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports

import { assertCannotReach } from "front-end-helpers"
import App from "../init/app.js"

//* #endregion ----------------------------------------------- Module Imports

export default {
	execute: (data: GlobalActions["auth-form-link-clicked"]) => {
		const { target } = data

		switch (target) {
			case "reset-password": {
				App.getAppComponents().ResetPassModal.show()
				break
			}
			default:
				assertCannotReach(target)
		}
	}
}
