//* #region ----------------------------------------------- Types
import type { MyComponentTypes } from "haq"

type T = MU_LoginForm & {
	globalEvents: {
		"login-form-submitted": {
			Form: X["LoginForm"]
			SubmitBtn: X["SubmitBtn"]
		}
	}
}

export type T_LoginForm = T

type E = T["globalEvents"]
type X = MyComponentTypes<T>

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only utility/helper modules)

import { $, _, makeAppEvents } from "haq"

//* #endregion ----------------------------------------------- Module Imports (only utility/helper modules)

export default function makeLoginForm() {
	let initialized = false

	const Form = _<T["LoginForm"]>("loginForm")
	const SubmitBtn = $<T["SubmitBtn"]>(Form, "[x_selector='submitBtn']")[0]

	const AppEvents = makeAppEvents<E>()

	_init()

	function _init() {
		if (initialized) return

		Form.addEventListener("submit", _onSubmit)

		initialized = true
	}

	function _onSubmit() {
		AppEvents.emit({
			action: "login-form-submitted",
			data: {
				Form,
				SubmitBtn
			}
		})
	}
}
