//* #region ----------------------------------------------- Types

import type { T_LoginForm } from "@ComposedComponents/AuthForms/LoginForm.js"
import type { T_AuthFormLink } from "@WebComponents/Auth/AuthFormLink/AuthFormLink.js"

export type GlobalActions = T_LoginForm["globalEvents"] &
	T_AuthFormLink["globalEvents"] &
	T_ResetPassModal["globalEvents"]

type ExecutedAction<T extends keyof GlobalActions> = Readonly<{
	execute: (data: GlobalActions[T]) => void
}>

type Router = {
	[key in keyof GlobalActions]: ExecutedAction<key>
}

//* #endregion ----------------------------------------------- Types

//* #region ----------------------------------------------- Module Imports (only events)

import loginFormSubmitted from "../events/login-form-submitted.js"

import authFormLinkClicked from "../events/auth-form-link-clicked.js"

import type { T_ResetPassModal } from "@ComposedComponents/AuthForms/ResetPassModal.js"
import resetFormSubmitted from "../events/reset-form-submitted.js"

//* #endregion ----------------------------------------------- Module Imports (only events)

function makeEventRouter() {
	const router: Router = {
		"login-form-submitted": loginFormSubmitted,
		"auth-form-link-clicked": authFormLinkClicked,
		"reset-form-submitted": resetFormSubmitted
	}

	return Object.freeze({
		get
	})

	function get<T extends keyof Router>(action: T) {
		return router[action]
	}
}

//* ---------- EXPORTS

const EVENT_ROUTER = makeEventRouter()

export default EVENT_ROUTER
